import React from "react"
import SimpleLayout from '../components/simple-layout';
import FACMatkustajapalvelut from '../components/fac-matkustajapalvelut';

const typeformId = "PUMlLPSW";

export default function Palautelomake(props) {
    const pagemeta={
      title: 'Matkahuolto - anna palautetta matkustajapalveluista | No No No',
      description: ''
    }

    return (    
      <SimpleLayout children={<FACMatkustajapalvelut />} pagemeta={pagemeta} />
    );
  };