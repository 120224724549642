import React from "react";
import Hero from "./hero";
import CampaignPhases from "./campaign-phases-matkustajapalvelut";
import {createPopup, createWidget} from "@typeform/embed";
import Button  from "@material-ui/core/Button";
import Box  from "@material-ui/core/Box";
import "@typeform/embed/build/css/popup.css"; // import necessary CSS
import styled from 'styled-components';


const typeformId = "PUMlLPSW";

export default function FACMatkustajapalvelut({serverUrl}) {
    const widget = React.useRef();

    const popUp = () => {
        createPopup(typeformId, {with:'500px'}).open()
    }
    
    React.useEffect(() => {
        createWidget(typeformId, {container: widget.current});
    }, []);

    return (
        <>
            <div className={"iframe-div"} ref={widget}></div>
        </>

    );

}